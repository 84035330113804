/*
 * Copyright Anemoi Software Inc. (c) 2021.
 * All right reserved.
 * Company secret. Any and all disclosure is prohibited.
 */

import {BufferGeometry, Float32BufferAttribute, Vector3} from "three";

class SphereArrayGeometry extends BufferGeometry {

    constructor(start_x, start_y, start_z,
                count_x, count_y, count_z,
                pitch_x, pitch_y, pitch_z,
                inline,
                radius = 1, widthSegments = 8, heightSegments = 6, phiStart = 0, phiLength = Math.PI * 2, thetaStart = 0, thetaLength = Math.PI) {

        super();
        this.type = 'SphereGeometry';

        this.parameters = {
            radius: radius,
            widthSegments: widthSegments,
            heightSegments: heightSegments,
            phiStart: phiStart,
            phiLength: phiLength,
            thetaStart: thetaStart,
            thetaLength: thetaLength
        };

        widthSegments = Math.max(3, Math.floor(widthSegments));
        heightSegments = Math.max(2, Math.floor(heightSegments));

        const thetaEnd = Math.min(thetaStart + thetaLength, Math.PI);

        let index = 0;
        const grid = [];

        const vertex = new Vector3();
        const normal = new Vector3();

        // buffers

        const indices = [];
        let ball_count;
        if (inline) ball_count = count_x * count_y * count_z;
        else {
            const odd_x = Math.floor(count_x / 2) + count_x % 2;
            const odd_y = Math.floor(count_y / 2) + count_y % 2;
            const odd_z = Math.floor(count_z / 2) + count_z % 2;
            const even_x = Math.floor(count_x / 2);
            const even_y = Math.floor(count_y / 2);
            const even_z = Math.floor(count_z / 2);
            ball_count = odd_x * odd_y + even_x * even_y;
        }
        const vertices = new Float32Array(ball_count * (heightSegments + 1) * (widthSegments + 1) * 3);
        const normals = new Float32Array(ball_count * (heightSegments + 1) * (widthSegments + 1) * 3);
        const uvs = new Float32Array(ball_count * (heightSegments + 1) * (widthSegments + 1) * 2);

        let vertex_index = 0,
            normals_index = 0,
            uvs_index = 0;

        const u_sin = [];
        const u_cos = [];
        const v_sin = [];
        const v_cos = []

        for (let iy = 0; iy <= heightSegments; iy++) {
            const v = iy / heightSegments;
            v_sin.push(Math.sin(thetaStart + v * thetaLength));
            v_cos.push(Math.cos(thetaStart + v * thetaLength));
        }
        for (let ix = 0; ix <= widthSegments; ix++) {
            const u = ix / widthSegments;
            u_sin.push(Math.sin(phiStart + u * phiLength));
            u_cos.push(Math.cos(phiStart + u * phiLength));
        }
        // generate vertices, normals and uvs

        if (inline) {
            for (let dx = 0; dx < count_x; ++dx) {
                for (let dy = 0; dy < count_y; ++dy) {
                    for (let dz = 0; dz < count_z; ++dz) {

                        for (let iy = 0; iy <= heightSegments; iy++) {

                            const verticesRow = [];

                            const v = iy / heightSegments;

                            // special case for the poles

                            let uOffset = 0;

                            if (iy == 0 && thetaStart == 0) {

                                uOffset = 0.5 / widthSegments;

                            } else if (iy == heightSegments && thetaEnd == Math.PI) {

                                uOffset = -0.5 / widthSegments;

                            }

                            for (let ix = 0; ix <= widthSegments; ix++) {

                                const u = ix / widthSegments;

                                // vertex

                                vertex.x = radius * u_cos[ix] * v_sin[iy];
                                vertex.y = radius * v_cos[iy];
                                vertex.z = radius * u_sin[ix] * v_sin[iy];

                                // vertices.push(start_x + dx * pitch_x - vertex.x, start_y + dy * pitch_y + vertex.y, start_z + dz * pitch_z + vertex.z);
                                vertices[vertex_index++] = start_x + dx * pitch_x - vertex.x;
                                vertices[vertex_index++] = start_y + dy * pitch_y + vertex.y;
                                vertices[vertex_index++] = start_z + dz * pitch_z + vertex.z;

                                // normal

                                normal.copy(vertex).normalize();
                                // normals.push(normal.x, normal.y, normal.z);
                                normals[normals_index++] = normal.x;
                                normals[normals_index++] = normal.y;
                                normals[normals_index++] = normal.z;

                                // uv

                                // uvs.push(u + uOffset, 1 - v);
                                uvs[uvs_index++] = u + uOffset;
                                uvs[uvs_index++] = 1 - v;

                                verticesRow.push(index++);

                            }

                            grid.push(verticesRow);

                        }

                        // indices
                        let rel = (heightSegments + 1) * (dx * count_y * count_z + dy * count_z + dz);
                        for (let iy = 0; iy < heightSegments; iy++) {

                            for (let ix = 0; ix < widthSegments; ix++) {

                                const a = grid[rel + iy][ix + 1];
                                const b = grid[rel + iy][ix];
                                const c = grid[rel + iy + 1][ix];
                                const d = grid[rel + iy + 1][ix + 1];

                                if (iy !== 0 || thetaStart > 0) indices.push(a, b, d);
                                if (iy !== heightSegments - 1 || thetaEnd < Math.PI) indices.push(b, c, d);

                            }

                        }
                    }
                }
            }
        } else {
            const odd_x = Math.floor(count_x / 2) + count_x % 2;
            const odd_y = Math.floor(count_y / 2) + count_y % 2;
            const odd_z = Math.floor(count_z / 2) + count_z % 2;
            const even_x = Math.floor(count_x / 2);
            const even_y = Math.floor(count_y / 2);
            const even_z = Math.floor(count_z / 2);

            for (let dx = 0; dx < odd_x; ++dx) {
                for (let dy = 0; dy < odd_y; ++dy) {
                    for (let dz = 0; dz < count_z; ++dz) {

                        for (let iy = 0; iy <= heightSegments; iy++) {

                            const verticesRow = [];

                            const v = iy / heightSegments;

                            // special case for the poles

                            let uOffset = 0;

                            if (iy == 0 && thetaStart == 0) {

                                uOffset = 0.5 / widthSegments;

                            } else if (iy == heightSegments && thetaEnd == Math.PI) {

                                uOffset = -0.5 / widthSegments;

                            }

                            for (let ix = 0; ix <= widthSegments; ix++) {

                                const u = ix / widthSegments;

                                // vertex

                                vertex.x = radius * u_cos[ix] * v_sin[iy];
                                vertex.y = radius * v_cos[iy];
                                vertex.z = radius * u_sin[ix] * v_sin[iy];

                                // vertices.push(start_x + dx * pitch_x - vertex.x, start_y + dy * pitch_y + vertex.y, start_z + dz * pitch_z + vertex.z);
                                vertices[vertex_index++] = start_x + dx * pitch_x - vertex.x;
                                vertices[vertex_index++] = start_y + dy * pitch_y + vertex.y;
                                vertices[vertex_index++] = start_z + dz * pitch_z + vertex.z;

                                // normal

                                normal.copy(vertex).normalize();
                                // normals.push(normal.x, normal.y, normal.z);
                                normals[normals_index++] = normal.x;
                                normals[normals_index++] = normal.y;
                                normals[normals_index++] = normal.z;

                                // uv

                                // uvs.push(u + uOffset, 1 - v);
                                uvs[uvs_index++] = u + uOffset;
                                uvs[uvs_index++] = 1 - v;

                                verticesRow.push(index++);

                            }

                            grid.push(verticesRow);

                        }

                        // indices
                        let rel = (heightSegments + 1) * (dx * odd_y * count_z + dy * count_z + dz);
                        for (let iy = 0; iy < heightSegments; iy++) {

                            for (let ix = 0; ix < widthSegments; ix++) {

                                const a = grid[rel + iy][ix + 1];
                                const b = grid[rel + iy][ix];
                                const c = grid[rel + iy + 1][ix];
                                const d = grid[rel + iy + 1][ix + 1];

                                if (iy !== 0 || thetaStart > 0) indices.push(a, b, d);
                                if (iy !== heightSegments - 1 || thetaEnd < Math.PI) indices.push(b, c, d);

                            }

                        }
                    }
                }
            }

            for (let dx = 0; dx < even_x; ++dx) {
                for (let dy = 0; dy < even_y; ++dy) {
                    for (let dz = 0; dz < count_z; ++dz) {

                        for (let iy = 0; iy <= heightSegments; iy++) {

                            const verticesRow = [];

                            const v = iy / heightSegments;

                            // special case for the poles

                            let uOffset = 0;

                            if (iy == 0 && thetaStart == 0) {

                                uOffset = 0.5 / widthSegments;

                            } else if (iy == heightSegments && thetaEnd == Math.PI) {

                                uOffset = -0.5 / widthSegments;

                            }

                            for (let ix = 0; ix <= widthSegments; ix++) {

                                const u = ix / widthSegments;

                                // vertex

                                vertex.x = radius * u_cos[ix] * v_sin[iy];
                                vertex.y = radius * v_cos[iy];
                                vertex.z = radius * u_sin[ix] * v_sin[iy];

                                // vertices.push(start_x + dx * pitch_x - vertex.x, start_y + dy * pitch_y + vertex.y, start_z + dz * pitch_z + vertex.z);
                                vertices[vertex_index++] = start_x + dx * pitch_x + pitch_x / 2 - vertex.x;
                                vertices[vertex_index++] = start_y + dy * pitch_y + pitch_y / 2 + vertex.y;
                                vertices[vertex_index++] = start_z + dz * pitch_z + vertex.z;

                                // normal

                                normal.copy(vertex).normalize();
                                // normals.push(normal.x, normal.y, normal.z);
                                normals[normals_index++] = normal.x;
                                normals[normals_index++] = normal.y;
                                normals[normals_index++] = normal.z;

                                // uv

                                // uvs.push(u + uOffset, 1 - v);
                                uvs[uvs_index++] = u + uOffset;
                                uvs[uvs_index++] = 1 - v;

                                verticesRow.push(index++);

                            }

                            grid.push(verticesRow);

                        }

                        // indices
                        let rel = (heightSegments + 1) * (odd_x * odd_y + dx * even_y * count_z + dy * count_z + dz);
                        for (let iy = 0; iy < heightSegments; iy++) {

                            for (let ix = 0; ix < widthSegments; ix++) {

                                const a = grid[rel + iy][ix + 1];
                                const b = grid[rel + iy][ix];
                                const c = grid[rel + iy + 1][ix];
                                const d = grid[rel + iy + 1][ix + 1];

                                if (iy !== 0 || thetaStart > 0) indices.push(a, b, d);
                                if (iy !== heightSegments - 1 || thetaEnd < Math.PI) indices.push(b, c, d);

                            }

                        }
                    }
                }
            }
        }
        // build geometry
        this.setIndex(indices);
        this.setAttribute('position', new Float32BufferAttribute(vertices, 3));
        this.setAttribute('normal', new Float32BufferAttribute(normals, 3));
        this.setAttribute('uv', new Float32BufferAttribute(uvs, 2));
        console.timeEnd();
    }

}

export default SphereArrayGeometry;
